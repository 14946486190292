import {dom} from 'core-utils';
import {STCK_STORE, SELECTORS, ACTIONS} from '@oneaudi/stck-store';
import {scPage} from '../stockcars-utils-bundle';
import {RequestController} from '../stockcars-bundle';

class CompareControllerClass {
	constructor() {
		this._addEventListener();
	}

	static get defaults() {
		return {
			'classAddCompare': '.js-add-compare',
			'classRemoveCompare': '.js-remove-compare',
			'classComparePanel': '.sc-compare-panel-view'
		};
	}

	/**
	 * Add to compare list click handler
	 * @param {event }event - event
	 * @return {void} returns nothing
	 */
	_addCompareClickHandler(event) {
		event.preventDefault();
		const addBtn = event.target;
		const column = dom.closest(addBtn, '.sc-j-car-tile');

		if (column) {
			const vehicleId = column.getAttribute('data-vehicle-id');
			if (SELECTORS.VEHICLES.getCompareVehicleIdsState(STCK_STORE.state).indexOf(vehicleId) === -1) {
				STCK_STORE.dispatch(ACTIONS.COMPARED_VEHICLES.addVehicleIdToCompare({vehicleId}));
			}
			else {
				STCK_STORE.dispatch(ACTIONS.COMPARED_VEHICLES.removeVehicleIdFromCompare({vehicleId}));
			}
			STCK_STORE.dispatch(ACTIONS.COMPARE_VEHICLES.resetCompareVehicles());
		}
	}

	/**
	 * Remove from Compare list
	 * @param {event } event - Event
	 * @returns {void} returns nothing
	 */
	_removeCompareClickHandler(event) {
		event.preventDefault();
		const vehicleId = event.target.getAttribute('data-vehicle-id');
		STCK_STORE.dispatch(ACTIONS.COMPARED_VEHICLES.removeVehicleIdFromCompare({vehicleId}));
		STCK_STORE.dispatch(ACTIONS.COMPARE_VEHICLES.resetCompareVehicles());
	}

	/**
	 * add all Event Listener
	 *
	 * @method addEventListener
	 * @return {void} returns nothing
	 */
	_addEventListener() {
		const domDelegate = dom.getEventDelegate('body');
		domDelegate.on('click', CompareControllerClass.defaults.classAddCompare, this._addCompareClickHandler);
		domDelegate.on('click', CompareControllerClass.defaults.classRemoveCompare, this._removeCompareClickHandler);
	}


	clearCompareVehicles() {
		STCK_STORE.dispatch(ACTIONS.COMPARED_VEHICLES.resetCompareVehicleIds());
	}

	async loadCompareResults() {
		let vehiclesIds = SELECTORS.VEHICLES.getCompareVehicleIdsState(STCK_STORE.state);
		if (!vehiclesIds.length) {
			vehiclesIds = scPage.getUrlParameterByName('compare', location.href);
			if (!vehiclesIds) {
				throw new Error('No Vehicle Ids found');
			}
		}
		const compareResult = await this.fetchCompareVehicles(vehiclesIds).catch(error => console.error(error));
		STCK_STORE.dispatch(ACTIONS.COMPARE_VEHICLES.addVehiclesToCompare({vehicles: compareResult}));
		if (!SELECTORS.VEHICLES.getCompareVehicleIdsState(STCK_STORE.state).length) {
			const {basic = []} = compareResult;
			// eslint-disable-next-line no-unused-expressions
			basic.forEach(vehicle => { vehicle.vehicle_id && STCK_STORE.dispatch(ACTIONS.COMPARED_VEHICLES.addVehicleIdToCompare({vehicleId: vehicle.vehicle_id})); });
		}
		return compareResult;
	}


	/**
	 * fetch compare vehicle data
	 * @param {array} vehicleIds array of vehicleIds to be compared
	 * @returns {Promise} error or response wrapped in a promise
	 */
	async fetchCompareVehicles(vehiclesIds = []) {
		try {
			if (!vehiclesIds.length || vehiclesIds.length === 0) {
				throw new Error('error fetching compare vehicles: missing vehicle ids');
			}
			let marketParams = scPage.getMarket().split('/');
			const filterParam = new Map();
			filterParam.set('market', marketParams[0]);
			filterParam.set('language', marketParams[1]);
			filterParam.set('vehicleids', vehiclesIds);

			const response = await RequestController.fetchCompareVehicles(filterParam);
			await STCK_STORE.waitForStateFromStorePromise({conditionFn: (state) => !!state.country});
			return response;
		}
		catch (error) {
			// @TODO handle errors
			console.error(error);
			throw error;
		}
	}
}

const compareController = new CompareControllerClass();

export {compareController, CompareControllerClass};

