import '../runtimePublicPath';
import {appEvents} from 'core-application';
import {CoreEvents} from '@audi/audi-core-events';
import {dom, template} from 'core-utils';
import {globalEventEmitter} from '../stockcars-bundle';
import {compareErrorTemplate} from './compare-error-template';
import {SELECTORS, STCK_STORE, ACTIONS} from '@oneaudi/stck-store';
import {
	FORMAT_UTILS, intersectionObserver,
	IntersectionObserverClass,
	scPage
} from '../stockcars-utils-bundle';
import {compareController} from './compare-controller';

export default class CompareElement extends HTMLElement {
	constructor() {
		super();
		this._globalEventBus = globalEventEmitter;
		this.resultObject = null;
		this._addEventListener();
	}

	/**
	 * static getter for defaults - selectors, strings, attributes
	 * @static
	 * @returns {object} defaults
	 */
	static get defaults() {
		return {
			'classCompareView': '.sc-j-compare-view',
			'classCompareShowHide': '.sc-j-compare-view #sc-j-compare-show-all',
			'classBacklink': '.sc-j-compare-view-back',
			'classMoveForSticky': '.sc-j-compare-move-for-sticky',
			'classDisclaimer': '.sc-compare-disclaimer',
			'classLinkContainer': '.sc-compare-link-container',
			'classPlaceholderForSticky': '.sc-j-compare-placeholder-for-sticky',
			'classCarImage': '.sc-compare-car-image',
			'classCarName': '.sc-compare-car-name',
			'classCompareComponent': '.sc-j-compare-view-component',
			'classProgressBar': '.sc-j-compare-progress',
			'classScrollChecker': '.sc-j-compare-scroll-check',
			'classStickyWrapper': '.sc-j-compare-cars-wrapper',
			'classCarsContainer': '.sc-j-compare-cars-container',
			'classCarImageWrapper': '.sc-j-compare-car-image-wrapper',
			'classCollapseToggle': '.sc-compare-collapse-toggle',
			'classFixed': 'sc-j-compare-sticky',
			'classDetailsLink': '.sc-details-link'
		};
	}

	/**
	 * custom elements connected callback function
	 * @override
	 * @returns {void} void
	 */
	connectedCallback() {
		this._renderCompareView();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} void
	 */
	disconnectedCallback() {
		if (this.stickyScrollMarker) {
			intersectionObserver.unregisterObserver(this.stickyScrollMarker);
		}
		if (this.hideCarContainerAtFooter) {
			intersectionObserver.unregisterObserver(this.hideCarContainerAtFooter);
		}
	}

	/**
	 * _renderCompareView
	 * @returns {Promise<void>} Promise<void>
	 * @private
	 */
	async _renderCompareView() {
		const compareResults = await compareController.loadCompareResults().catch((error) => {
			console.warn(error);
			this._renderCompareComponent(this._renderErrorTemplateString());
		});
		if (!compareResults || !Array.isArray(compareResults.basic) || !compareResults.basic.length) {
			this._renderCompareComponent(this._renderErrorTemplateString());
		}
		else {
			// TODO add special Links etc.
			await this._render(compareResults);
		}
		this._setBackLink();
		this._checkExistence(compareResults);
	}

	/**
	 * empty non existant vehicles from store
	 * @param {Object} compareResults - data
	 *  @returns {void} returns nothing
	 */
	_checkExistence(compareResults) {
		compareResults.basic.forEach(vehicle => {
			if (!vehicle.exists) {
				let vehicleId = vehicle.vehicle_id;
				STCK_STORE.dispatch(ACTIONS.COMPARED_VEHICLES.removeVehicleIdFromCompare({vehicleId}));
			}
		});
	}

	/**
	 * render the DOM
	 * @method render
	 * @param {Object} compareResults - data
	 * @returns {void} returns nothing
	 */
	async _render(compareResults) {
		if (!compareResults) {
			throw new Error('No compare data available');
		}

		this._scDetailLinkPattern = dom.getElement(CompareElement.defaults.classCompareComponent).getAttribute('data-details-url') || '';
		this._scResultsLink = dom.getElement(CompareElement.defaults.classCompareComponent).getAttribute('data-results-link') || '';
		// add favorite status
		compareResults.basic.forEach((vehicle) => {
			vehicle.favorite = SELECTORS.VEHICLES.isFavoriteVehicle(STCK_STORE.state, vehicle.vehicle_id);
		});
		// patch detail link
		compareResults.basic.forEach(this._addDetailLinks.bind(this));
		compareResults.basic.forEach(this._handlePicUrl.bind(this));
		// patch leasing
		compareResults.features.summary = this._addLeasing(compareResults.basic, compareResults.features.summary);
		// patch warranty
		compareResults.features.summary = this._addWarranty(compareResults.features.attributes, compareResults.features.summary);
		// patch eCommerce
		compareResults.features.summary = this._addBuyableOnline(compareResults.features.attributes, compareResults.features.summary);
		// patch q4etron carline
		compareResults.basic = this._addQ4EtronCarline(compareResults.basic);
		const templateString = await this._renderCompareTemplateString(compareResults);
		this._renderCompareComponent(templateString);
	}

	/**
	 * renderCompareComponent
	 * @param {String} templateString_ rendered template string
	 * @return {void} returns nothing
	 */
	_renderCompareComponent(templateString_ = '') {
		const container = this.querySelector(CompareElement.defaults.classCompareView);
		if (!container) {
			return;
		}

		container.innerHTML = '';
		dom.appendHtmlString(container, templateString_);

		const disclaimer = this.querySelector(CompareElement.defaults.classDisclaimer),
			linkContainer = this.querySelector(CompareElement.defaults.classLinkContainer),
			progressBar = this.querySelector(CompareElement.defaults.classProgressBar);

		[container, disclaimer, linkContainer].forEach((element) => {
			if (element) {
				element.classList.remove('sc-hidden');
			}
		});
		if (progressBar) {
			progressBar.classList.add('sc-hidden');
		}

		const contentRendered = new CustomEvent(appEvents.CONTENT_RENDERED, {
			element: container,
			detail: {element: container}
		});
		document.dispatchEvent(contentRendered);

		this._hookObserversForStickyNavigation();
	}

	/**
	 * _hookObserversForStickyNavigation
	 * @returns {void} returns nothing
	 */
	_hookObserversForStickyNavigation() {
		this.stickyScrollMarker = this.querySelector(CompareElement.defaults.classScrollChecker);
		this.hideCarContainerAtFooter = this.querySelector(CompareElement.defaults.classPlaceholderForSticky);
		if (this.stickyScrollMarker) {
			intersectionObserver.registerObserver(this.stickyScrollMarker, this._handleIntersectionForStickyNavigation.bind(this), {threshold: [0.5]});
		}
		if (this.hideCarContainerAtFooter) {
			intersectionObserver.registerObserver(this.hideCarContainerAtFooter, this._handleIntersectionForCarContainerAtFooter.bind(this), {threshold: [0, 1]});
		}
	}

	/**
	 * handleIntersectionForStickyNavigation
	 * @param {Array<Object>} entries intersection observer entries
	 * @private
	 * @return {void} void
	 */
	_handleIntersectionForStickyNavigation(entries = []) {
		if (!IntersectionObserverClass.isElementVisible(entries)) {
			if (entries[0] && entries[0].boundingClientRect.top <= 0) {
				this._addStickyState();
			}
		}
		else {
			if (entries[0] && entries[0].boundingClientRect.top >= 0) {
				this._removeStickyState();
			}
		}
	}

	_handleIntersectionForCarContainerAtFooter(entries = []) {
		const cmpStickyWrapper = dom.getElement(CompareElement.defaults.classStickyWrapper, this);
		let height = cmpStickyWrapper.offsetHeight;
		if (entries[0] && entries[0].boundingClientRect.top >= 0) {
			cmpStickyWrapper.style.top = '0px';
		}
		else {
			cmpStickyWrapper.style.top = `-${height}px`;
		}
	}

	/**
	 * _repositionContentForSticky the sticky element animates to a lower height, here it's relative placeholder adapts
	 * @param {HTMLElement} cmpCarImageWrapper – the node that has the EventListener
	 * @param {Array<HTMLElement>} cmpMoveForStickyItems – gets a reduced margin as the sticky element is reduced in height
	 * @param {HTMLElement} cmpComparePlaceholder – gets a reduced margin as the sticky element is reduced in height
	 * @private
	 * @returns {void} void
	 */
	_repositionContentForSticky(cmpCarImageWrapper, cmpMoveForStickyItems, cmpComparePlaceholder) {
		let cmpScrollCheckOffsetHeightReduced = 200;
		cmpCarImageWrapper.removeEventListener('transitionend', this._repositionContentForSticky);
		cmpScrollCheckOffsetHeightReduced = dom.getElement(CompareElement.defaults.classCarName, this).offsetHeight;
		cmpMoveForStickyItems.forEach((stickyItem) => {
			stickyItem.style.transition = 'transform 1.9s';
			stickyItem.style.transform = 'translate3d(0,' + cmpScrollCheckOffsetHeightReduced + 'px,0)';
		});
		cmpComparePlaceholder.style.transition = 'height .8s';
		cmpComparePlaceholder.style.height = cmpScrollCheckOffsetHeightReduced + 'px';
	}

	/**
	 * _addStickyState adds the sticky state to the cars wrapper, sets placeholder space and animates the sticky elements' reduced height
	 * @param {Number} cmpScrollCheckOffsetHeightExpanded height of expanded sticky element
	 * @private
	 * @returns {void} void
	 */
	_addStickyState() {
		let cmpScrollCheckOffsetHeightExpanded = dom.getElement(CompareElement.defaults.classStickyWrapper, this).offsetHeight || 0;
		const cmpStickyWrapper = dom.getElement(CompareElement.defaults.classStickyWrapper, this);
		const cmpCarsContainer = dom.getElement(CompareElement.defaults.classCarsContainer, this);
		const cmpCarImageWrapper = dom.getElement(CompareElement.defaults.classCarImageWrapper, this);
		const stickyClassName = CompareElement.defaults.classFixed;
		let cmpMoveForStickyItems = dom.getElementsArray(CompareElement.defaults.classMoveForSticky, this);
		let cmpComparePlaceholder = dom.getElement(CompareElement.defaults.classPlaceholderForSticky, this);
		cmpStickyWrapper.classList.add(stickyClassName);
		cmpCarsContainer.classList.add(stickyClassName);
		cmpMoveForStickyItems.forEach((stickyItem) => {
			stickyItem.style.transform = 'translate3d(0,' + cmpScrollCheckOffsetHeightExpanded + 'px,0)';
		});
		cmpComparePlaceholder.style.height = cmpScrollCheckOffsetHeightExpanded + 'px';
		cmpCarImageWrapper.addEventListener('transitionend', this._repositionContentForSticky(cmpCarImageWrapper, cmpMoveForStickyItems, cmpComparePlaceholder));
	}

	/**
	 * _removeStickyState removes the sticky state and resets the space placeholder value
	 * @private
	 * @returns {void} void
	 */
	_removeStickyState() {
		const cmpStickyWrapper = dom.getElement(CompareElement.defaults.classStickyWrapper, this);
		const cmpCarsContainer = dom.getElement(CompareElement.defaults.classCarsContainer, this);
		const stickyClassName = CompareElement.defaults.classFixed;
		let cmpMoveForStickyItems = dom.getElementsArray(CompareElement.defaults.classMoveForSticky, this);
		let cmpComparePlaceholder = dom.getElement(CompareElement.defaults.classPlaceholderForSticky, this);
		cmpStickyWrapper.classList.remove(stickyClassName);
		cmpCarsContainer.classList.remove(stickyClassName);
		cmpMoveForStickyItems.forEach((stickyItem) => {
			stickyItem.style.transform = 'translate3d(0,0,0)';
			stickyItem.style.transition = 'initial';
		});
		cmpComparePlaceholder.style.height = '0px';
		cmpComparePlaceholder.style.transition = 'initial';
	}

	/**
	 * renderCompareTemplateString renders compare template string from compareData
	 * @param {Object} compareData compareData
	 * @return {string} rendered template string
	 */
	async _renderCompareTemplateString(compareData = {features: {}}) {

		try {
			const COMPARE_PARTS = await import(/* webpackChunkName: "compare-parts" */ './parts/index.js');
			const compareHeaderPartTemplateString = COMPARE_PARTS.CompareHeaderPart.getTemplateString(compareData),
				compareSummaryPartTemplateString = COMPARE_PARTS.CompareSummaryPart.getTemplateString(compareData),
				compareConsumptionEmissionTemplateString = COMPARE_PARTS.CompareConsumptionEmissionPart.getTemplateString(compareData),
				compareTechDataTemplateString = COMPARE_PARTS.CompareTechDataPart.getTemplateString(compareData),
				compareDealerTemplateString = await COMPARE_PARTS.CompareDealerPart.getTemplateString(compareData),
				compareAttributesTemplateString = COMPARE_PARTS.CompareAttributesPart.getTemplateString(compareData),
				templateString = compareHeaderPartTemplateString + compareSummaryPartTemplateString + compareAttributesTemplateString + compareTechDataTemplateString + compareConsumptionEmissionTemplateString + compareDealerTemplateString + '</div>';
			return templateString;
		}
		catch (err) {
			console.log("dynamic includes failed", err);
			return "";
		}
	}

	/**
	 * renderErrorTemplateString renders Error compare template
	 * @return {string} returns error template string
	 */
	_renderErrorTemplateString() {
		const disclaimerComponent = this.querySelector(CompareElement.defaults.classDisclaimer),
			linkComponent = this.querySelector(CompareElement.defaults.classLinkContainer),
			container = this.querySelector(CompareElement.defaults.classCompareView);

		disclaimerComponent.classList.add('sc-hidden');
		linkComponent.classList.add('sc-hidden');
		container.classList.remove('sc-hidden');

		const resultsUrl = this.getAttribute('data-results-link');
		const templateString = template.render(compareErrorTemplate, {
			resultsUrl: resultsUrl
		});
		return templateString;
	}

	/**
	 * strip vehicleIds from url
	 * @method stripVehicleIdsFromUrl
	 * @returns {void} returns nothing
	 */
	_stripVehicleIdsFromUrl() {
		if (location.href.indexOf('sc_compare') === -1) {
			if (location.href.indexOf('compare=') > 0) {
				window.location.href = window.location.href.replace(/(&|\?)(compare=)\w*\,\w*/g, '');// eslint-disable-line no-useless-escape
			}
		}
		else if (location.hash.length > 0 && location.hash.indexOf('sc_compare') > 0) {
			this._setUrlCompareVehicleIdsParam();
		}
		// Jump in with vehicleIds
		else if (location.hash.length > 0 && location.pathname.indexOf('sc_compare') > 0) {
			if (location.href.indexOf('compare=') > 0) {
				if (window.history !== undefined && window.history.pushState !== undefined) {
					window.history.pushState({}, document.title, window.location.href.replace(/(&|\?)(compare=)\w*\,\w*/g, ''));// eslint-disable-line no-useless-escape
				}
			}
		}
	}

	/**
	 * set url compare vehicleIds param
	 * @method setUrlCompareVehicleIdsParam
	 * @return {void} nothing
	 */
	_setUrlCompareVehicleIdsParam() {
		if (location.href.indexOf('compare=') === -1) {
			const sepElement = scPage.getSeparatingElement();
			const vehicleids = compareController.compareVehicleIds;
			if (vehicleids) {
				window.location.hash += sepElement + 'compare=' + vehicleids;
			}
		}
	}

	/**
	 * add Links before template rendering
	 * @method addDetailLinks
	 * @param {Object} value - detail Link object
	 * @return {void} void
	 */
	_addDetailLinks(value = {}) {
		value.url = (this._scDetailLinkPattern || '').replace(/SC_VEHICLE_ID/i, value.vehicle_id);
	}

	_handlePicUrl(vehicleBasic = {}) {
		if (!vehicleBasic.pictures && !vehicleBasic.pictures.vtp4x3n1c) {
			return;
		}

		const isUsedCar = vehicleBasic.type.toLowerCase() === 'u';
		const renderImages = vehicleBasic.pictures.vtp4x3n1c; // also used as fallback if no dealer images are available

		if (isUsedCar || vehicleBasic.hideRenderImages) {
			if (vehicleBasic.pictures['dealer-images'] && vehicleBasic.pictures['dealer-images'][0]) {
				vehicleBasic.picture = vehicleBasic.pictures['dealer-images'][0];
			}
			else {
				vehicleBasic.picture = renderImages[0];
			}
			return;
		}

		vehicleBasic.picture = renderImages[0];
	}

	_addLeasing(basic = [], summary) {
		let leasingObject = {
				leasingCar: {
					id: 'leasing-car',
					label: `<span>${window.i18n['sc.details.leasing.label']}</span> <span class="sc-leasing-element-label-buyable-online">${window.i18n['sc.details.leasing.buyable-online']}</span>`,
					genericTextkey: `<span>${window.i18n['sc.details.leasing.label']}</span> <span class="sc-leasing-element-label-buyable-online">${window.i18n['sc.details.leasing.buyable-online']}</span>`,
					values: []
				}
			},
			flag = false;

		basic.forEach(vehicle => {
			leasingObject.leasingCar.values.push(vehicle.leasingCar);
			if (vehicle.leasingCar) {
				flag = true;
			}
		});
		return flag ? Object.assign({}, summary, leasingObject) : summary;
	}

	/**
	 * adds buyableOnline from attributes to summary node
	 * @param {Object} attributes attributes
	 * @param {Object} summary summary
	 * @return {Object} returns new summary object
	 */

	_addBuyableOnline(attributes = {}, summary = {}) {
		let buyableOnlineObject = {
				buyableOnline: {
					id: 'buyable-online',
					label: 'buyable online',
					values: []
				}
			},
			buyableOnlineAvailable = false,
			newSummary;

		if (attributes['buyable-online'] && attributes['buyable-online'].data && attributes['buyable-online'].data.length) {
			attributes['buyable-online'].data.forEach(buyableOnline => {
				if (!buyableOnline.values || !buyableOnline.values.length) {
					return;
				}
				buyableOnlineObject.buyableOnline.values = buyableOnline.values;
				buyableOnlineAvailable = true;

			});
		}
		newSummary = buyableOnlineAvailable ? Object.assign({}, summary, buyableOnlineObject) : summary;
		return newSummary;
	}

	/**
	 * adds Warranties from attributes to summary node
	 * @param {Object} attributes attributes
	 * @param {Object} summary summary
	 * @return {Object} returns new summary object
	 */
	_addWarranty(attributes = {}, summary = {}) {
		let warrantyObject = {
				warranty: {
					id: 'warranty',
					label: `warranty`,
					values: []
				}
			},
			gwplusWarrantyObject = {
				gwplusWarranty: {
					id: 'gwplusWarranty',
					label: `gwplusWarranty`,
					values: []
				}
			},
			warrantiesAvailable = false,
			gwplusWarrantiesAvailable = false,
			newSummary;

		if (attributes['used-car-warranty-type'] && attributes['used-car-warranty-type'].data && attributes['used-car-warranty-type'].data.length) {
			attributes['used-car-warranty-type'].data.forEach(warranty => {
				if (!warranty.values || !warranty.values.length) {
					return;
				}
				if (warranty.id === 'used-car-warranty-type.plus') {
					gwplusWarrantyObject.gwplusWarranty.values = warranty.values;
					gwplusWarrantiesAvailable = gwplusWarrantyObject.gwplusWarranty.values.filter(value => value === true);
					return;
				}
				if (warranty.id !== 'used-car-warranty-type.gwplus5-years-extended') {
					warranty.values.forEach((vehicleHasWarranty, index) => {
						if (vehicleHasWarranty === true) {
							warrantyObject.warranty.values[index] = FORMAT_UTILS.getTextReplace(warranty.id, warranty.label);
							warrantiesAvailable = true;
						}
						else {
							if (warrantyObject.warranty.values[index] === undefined) {
								warrantyObject.warranty.values[index] = false;
							}
						}
					});
				}
			});
		}
		newSummary = warrantiesAvailable ? Object.assign({}, summary, warrantyObject) : summary;
		newSummary = gwplusWarrantiesAvailable ? Object.assign({}, newSummary, gwplusWarrantyObject) : newSummary;
		return newSummary;
	}


	/**
	 * adds symbolic carline if q4 etron
	 * @param {Object} basic basic
	 * @return {Object} returns new basic object
	 */
	_addQ4EtronCarline(basic) {
		basic.forEach(vehicleBasic => {
			if (vehicleBasic.carlineName && (vehicleBasic.carlineName.toLowerCase().includes('q4') && vehicleBasic.carlineName.toLowerCase().includes('tron'))) {
				vehicleBasic.symbolicCarline = {
					code: "q4etron",
					description: vehicleBasic.carlineName
				};
			}
		});
		return basic;
	}

	/**
	 * Show or hide all infoboxes
	 * @param {event} event clicked input
	 * @returns {void} returns nothing
	 */
	_addCompareShowHideClickHandler(event) {
		const checkboxesArr = dom.getElementsArray(CompareElement.defaults.classCollapseToggle);
		checkboxesArr.forEach(function (checkbox) {
			checkbox.checked = !!event.target.checked;
		});
	}

	/**
	 * @method getBackLink
	 * @returns {Object} returns previous page obj
	 */
	_getBackLink() {
		const prevPage = scPage.previousPageObject || '';
		if (!scPage.isDetailsPage(prevPage.url)) {
			this._previousPage = prevPage.url;
		}
		return this._previousPage || this._scResultsLink || '';
	}

	/**
	 * @method setBackLink
	 * @returns {void} returns nothing
	 */
	_setBackLink() {
		dom.getElement(CompareElement.defaults.classBacklink, this).href = this._getBackLink();
	}

	/**
	 * add all Event Listener
	 * @method addEventListener
	 * @returns {void} returns nothing
	 */
	_addEventListener() {
		const domDelegate = dom.getEventDelegate('body');
		domDelegate.on('click', CompareElement.defaults.classCompareShowHide, this._addCompareShowHideClickHandler);
		this._globalEventBus.on(CoreEvents.PAGE_LOADED, this._stripVehicleIdsFromUrl);
	}
}
if (window.customElements.get('compare-element') === undefined) {
	window.customElements.define('compare-element', CompareElement);
}
