import {contactLinkTemplate} from '../../stockcars-bundle';

const exports = `
{{var item = it.item;}}
{{? item.type === "link" }}
	${contactLinkTemplate}
{{?}}
`;

export {exports as compareButtonElementTemplate};
