const exports = `
<div class="sc-compare-panel-wrapper sc-j-compare-panel-wrapper" >
	<div class="sc-compare-panel sc-j-compare-panel" data-audicodes="{{~it.data :vehicle :index}}{{? index > 0}},{{?}}{{=vehicle.audiCode}}{{~}}">
		<input type="checkbox" id="sc-compare-panel-expand" {{? it.checkedState}}checked{{?}}>
		<div class="sc-compare-panel-items audi-copy-s">
			{{~it.data :vehicle :index}}
				<li class="sc-compare-panel-item {{? vehicle.used && vehicle.used.pictureUrls}}sc-compare-panel-uc{{?}} js-remove-compare" data-state="true" data-vehicle-id="{{=vehicle.id}}">
					{{=index+1}}
				</li>
			{{~}}
			{{? it.data.length < 2}}
				<li class="sc-compare-panel-item" data-state="false">
					2
				</li>
			{{?}}
			{{? it.data.length < 3}}
				<li class="sc-compare-panel-item" data-state="false">
					3
				</li>
			{{?}}
		</div>

		<label for="sc-compare-panel-expand" class="sc-compare-panel-expand-label">
			<svg class="nm-icon-down-small sc-compare-panel-expand-icon">
				<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/down-small.svg#audi-down-small"}}></use>
			</svg>
		</label>
		<div class="sc-compare-panel-collapsed-compare-button {{? it.data.length < 2}}sc-compare-panel-incomplete{{?}}">
			<a href="#" class="audi-copy-s sc-compare-panel-collapsed-compare-button-link">{{? it.data.length < 2}}{{=window.i18n['sc.compare.panel.button.inactive']||'sc.compare.panel.button.inactive'}}{{??}}{{=window.i18n['sc.compare.panel.button']||'sc.compare.panel.button'}}{{?}}</a>
		</div>
		<div class="sc-compare-panel-expanded">

			<div role="tooltip" class="sc-compare-panel-expanded-content">
				<div class="sc-compare-panel-expanded-items">
					{{function getVtpImagesWithDimensions(url_, width, height) {var parts = url_.split('/');if (parts.length < 7){return url_;}parts[5] = width;parts[6] = height;return parts.join('/');};}}
					{{for (index = 0; index < 3; index++) {}}
						{{? it.data[index]}}
							{{var vehicle = it.data[index];}}
							{{var url = "";}}
							{{var isUsedCar = vehicle.type.toLowerCase() === "u";}}
							{{? isUsedCar && vehicle.used && vehicle.used.pictureUrls && vehicle.used.pictureUrls.length > 0 && !vehicle.nationWideSelling}}
								{{url = vehicle.used.pictureUrls[0];}}
							{{?? vehicle.tilesPictures && vehicle.tilesPictures.length > 0}}
								{{url = vehicle.tilesPictures[0].url;}}
							{{?? vehicle.fallbackPictures && vehicle.fallbackPictures.length > 0}}
								{{var fallbackPicture = vehicle.fallbackPictures.find(function(pic){return pic.key === "render_4x3";});}}
								{{url = fallbackPicture.url;}}
							{{?}}
							<div class="sc-compare-panel-expanded-item{{? vehicle.used && vehicle.used.pictureUrls}} sc-compare-panel-uc{{?}}" data-vehicle-id="{{=vehicle.id}}">
								<a class="sc-compare-panel-expanded-item-number audi-copy-s sc-details-link nm-layerLink" href="{{=vehicle.url}}">{{=index+1}}</a>
								<div class="sc-compare-panel-expanded-item-image-container-wrapper">
									<a class="sc-compare-panel-expanded-item-image-container sc-details-link nm-layerLink" href="{{=vehicle.url}}">
										{{? url}}
											<div class="sc-compare-panel-expanded-item-image-wrapper">
												<picture class="sc-compare-panel-expanded-item-image">
													{{? url.indexOf('mediaservice') > -1}}
														{{var separator = "?";}}
														{{? url.indexOf('?') > -1}}
															{{separator = "&";}}
														{{?}}
														<source srcset="{{=url+separator}}wid=240&mimetype=image/webp" type="image/webp"/>
														<source srcset="{{=url+separator}}wid=240&mimetype=image/jp2" type="image/jp2"/>
														<img src="{{=url+separator}}width=240" style="width: 100%;">
													{{?? url.indexOf('vtpimages') > -1}}
														{{var url250x188 = getVtpImagesWithDimensions(url, '250','188');}}
														<img src="{{=url250x188}}" style="width: 100%;">
													{{??}}
														<img src="{{=url}}" style="width: 100%;">
													{{?}}
												</picture>
											</div>
										{{?}}
									</a>
								</div>
								<div class="sc-compare-panel-expanded-item-text">
									{{? vehicle && ((vehicle.symbolicCarline && !!vehicle.symbolicCarline.description) || (vehicle.modelCode && !!vehicle.modelCode.description)) }}
										<a class="sc-compare-panel-expanded-item-text-title audi-copy-s sc-details-link nm-layerLink" href="{{=vehicle.url}}">
										{{? vehicle.symbolicCarline && !!vehicle.symbolicCarline.description }}
											{{=vehicle.symbolicCarline.description}}&#160;
										{{?}}
										{{? vehicle && vehicle.modelCode && !!vehicle.modelCode.description }}
											{{=vehicle.modelCode.description}}
										{{?}}
										</a>
									{{?}}
									<a href="" class="sc-compare-panel-expanded-item-text-remove audi-link-s audi-link-s--underline js-remove-compare" data-vehicle-id="{{=vehicle.id}}">{{=window.i18n['sc.compare.panel.remove']||'sc.compare.panel.remove'}}</a>
								</div>
							</div>
						{{??}}
							<div class="sc-compare-panel-expanded-item-placeholder{{? index - it.data.length != 0 }} sc-compare-panel-hide-mobile{{?}}">
								<span class="sc-compare-panel-expanded-item-number audi-copy-s">{{=index+1}}</span>
								<div class="sc-compare-panel-expanded-item-image-container-wrapper">
									<div class="sc-compare-panel-expanded-item-image-container">
										<div class="sc-compare-panel-expanded-no-image-wrapper">
											<svg class="sc-compare-panel-expanded-no-image-icon nm-icon-car-large" tabindex="-1" viewBox="0 0 48 48">
												<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/nm-icon-car-large.svg#nm-icon-car-large"}} tabindex="-1"></use>
											</svg>
										</div>
									</div>
								</div>
								<div class="sc-compare-panel-expanded-item-text">
									<span class="sc-compare-panel-expanded-item-text-placeholder audi-copy-s">
										{{=window.i18n['sc.compare.panel.choose']||'sc.compare.panel.choose'}}
									</span>
								</div>
							</div>
						{{?}}
					{{};}}

				</div>
				<a href="#" class="sc-compare-panel-expanded-compare-button audi-button audi-button--ghost sc-compare-panel-expanded-compare-button-link" {{? it.data.length < 2}}data-state="inactive"{{?}}>
					<span class="audi-button__text">{{=window.i18n['sc.compare.panel.button']||'sc.compare.panel.button'}}</span>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="sc-j-compare-panel-placeholder-for-sticky"></div>`;

export {exports as comparePanelTemplate};


