import {BaseButtonLogic} from '../stockcars-bundle';
import {OBJECT_UTILS} from '../stockcars-utils-bundle';
import {template} from 'core-utils';
import {compareButtonElementTemplate} from './templates/compare-button-element-template';
import {SELECTORS, STCK_STORE} from "@oneaudi/stck-store";

export class CompareButtonElement extends BaseButtonLogic {
	constructor() {
		super();
	}

	render() {
		this.data.linkType = this.dataset.linkType;
		if (!this.data.leasing && this.data.linkType === 'compare') {
			const templateData = this._getCompareButtonObject();
			this.innerHTML = template.render(compareButtonElementTemplate, {item: templateData});
		}
	}

	_getPostData() {
		const vehicleId = this.data.id;
		const products = SELECTORS.AOZ.getAddedProductsByVehicleId(STCK_STORE.state, vehicleId);
		const financing = SELECTORS.FINANCING.getSummaryOnePointFive(STCK_STORE.state, vehicleId);
		const vehicleData = SELECTORS.VEHICLES.getVehiclesMap(STCK_STORE.state).get(vehicleId);
		const audiCode = vehicleData.audiCode;
		const postData = [
			{'aoz': products},
			{'audicode': audiCode},
			{'financingData': financing},
			{'vehicleData': vehicleData}
		];
		return encodeURIComponent(JSON.stringify(postData));
	}

	_getCompareButtonObject() {
		let buttonObject = {};
		let specialLinks = OBJECT_UTILS.deepCopy(this.data.ctaSpecialLinks);
		if (this.data.ecom) {
			buttonObject = Object.assign(specialLinks.ecom, {
				'type': 'ecom',
				'dataStoreUrl': this.data.vehicleData.vtpDataStoreUrl,
				'pdfUrl': this.data.pdfUrl,
				'vehicleId': this.data.vehicleData.id
			});
		}
		else {
			const linkData = this._getSpecificSpecialLink(specialLinks);
			buttonObject = Object.assign(linkData, {
				'primary': ((!!this.dataset && !!this.dataset.primary)),
				'url': this._getUrl(specialLinks),
				'type': 'link',
				'css': this._getCssClasses(linkData),
				'postData': this._getPostData()
			});
		}
		return buttonObject;
	}

	_getCssClasses(linkData_ = {}) {
		return (linkData_.target !== '_blank' && linkData_.layer && linkData_.method !== 'POST') ? 'nm-layerLink sc-j-layerLink sc-compare-dealer-contact-link' : 'sc-j-layerLink sc-compare-dealer-contact-link';
	}

	_getSpecificSpecialLink(ctaSpecialLinks_ = {}) {
		return (ctaSpecialLinks_.nws && this.data.vehicleData.nationWideSelling) ? ctaSpecialLinks_.nws : ctaSpecialLinks_.contact;
	}

	_getUrl(specialLinks_) {
		const data = this._getSpecificSpecialLink(specialLinks_) || {};
		return ((!!data.patternUrl && data.method === 'POST') ? data.patternUrl : data.url) || "";
	}
}

if (window.customElements.get('compare-button-element') === undefined) {
	window.customElements.define('compare-button-element', CompareButtonElement);
}
