const exports = `
	<div class="sc-cmp-headline">
		<h1 class="nm-el-hdl nm-el-hdl-02 nm-at-hdl-b">{{=window.i18n['sc.compare.headline']||'Fahrzeugvergleich'}}</h1>
	</div>

	<div class="sc-compare-error-wrap">
		<h2 class="nm-el-hdl nm-el-hdl-02 nm-at-hdl-b sc-compare-error-headline">{{=window.i18n['sc.compare.error.headline']||'Ein Fahrzeugvergleich ist momentan nicht möglich.'}}</h2>
		<p class="nm-el-pg nm-el-pg-03 sc-compare-error-copy">{{=window.i18n['sc.compare.error.copy']||'Bitte wählen Sie zunächst 2 Fahrzeuge aus, um diese miteinander zu vergleichen.'}}</p>
		<a href="{{=it.resultsUrl}}" class="nm-el-btn nm-el-btn-01 nm-el-btn-ast sc-new-search">{{=window.i18n['sc.compare.error.back.to.results']||'Zu den Ergebnissen'}}</a>
	</div>
`;

export {exports as compareErrorTemplate};
