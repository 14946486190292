import {dom, template} from 'core-utils';
import {globalEventEmitter} from '../stockcars-bundle';
import {intersectionObserver, IntersectionObserverClass, OBJECT_UTILS, scPage} from '../stockcars-utils-bundle';
import {comparePanelTemplate} from './templates/compare-panel-template';
import {SELECTORS, STCK_STORE} from '@oneaudi/stck-store';

export default class ComparePanelElement extends HTMLElement {
	constructor() {
		super();
		this._checkedState = true;
		this._scrollAmount = 0;
		this._globalEventBus = globalEventEmitter;
		this.unsubscribeFromCompareStore = null;
	}

	static get defaults() {
		return {
			classComparePanelView: '.sc-compare-panel-view',
			classComparePanel: '.sc-j-compare-panel',
			classCompareTooltip: '.sc-compare-panel-expand-label',
			idCompareCheckbox: '#sc-compare-panel-expand',
			classComparePanelPlaceholder: '.sc-j-compare-panel-placeholder-for-sticky',
			classFavoriteItems: '.sc-favorite-items',
			classMoveForSticky: '.sc-j-compare-panel-move-for-sticky',
			classResultItems: '.sc-result-items',
			classExpandedContent: '.sc-compare-panel-expanded-content',
			scrollChecker: '.sc-j-compare-panel-scroll-check',
			classStickyWrapper: '.sc-j-compare-panel-wrapper',
			classFixed: 'sc-j-compare-panel-sticky',
			classMainNavigation: '.sc-main-navigation',
			classCollapsedCompareButtonLink: '.sc-compare-panel-collapsed-compare-button-link',
			classExpandedCompareButtonLink: '.sc-compare-panel-expanded-compare-button-link',
			selectorComparePanelExpandedButton: '.sc-compare-panel-expanded-compare-button',
			selectorComparePanelExpandedItems: '.sc-compare-panel-expanded-items',
			selectorComparePanelItems: '.sc-compare-panel-items',
			selectorComparePanelExpanded: '.sc-compare-panel-expanded',
			selectorComparePanelCollapsedButton: '.sc-compare-panel-collapsed-compare-button'
		};
	}

	connectedCallback() {
		this._bindContext();
		this._addEventListener();
		this._removeStickyState();
		this._renderComparePanelView();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} void
	 */
	disconnectedCallback() {
		this._removeEventListener();
	}

	/**
	 * _bindContext to custom element
	 * @private
	 * @return {void} void
	 */
	_bindContext() {
		this._onShowHideChangeHandler = this._onShowHideChangeHandler.bind(this);
		this._collapseComparePanel = this._collapseComparePanel.bind(this);
		this._setMaxHeight = this._setMaxHeight.bind(this);
		this._getHeightForAnimation = this._getHeightForAnimation.bind(this);
		this._setTopSpacing = this._setTopSpacing.bind(this);
	}

	/**
	 * _renderComparePanelView starts the rendering process
	 * @private
	 * @return {void} void
	 */
	_renderComparePanelView() {
		const compareIds = SELECTORS.VEHICLES.getCompareVehicleIdsState(STCK_STORE.state);
		const compareResponse = [...SELECTORS.VEHICLES.getVehiclesMapByIds(STCK_STORE.state, compareIds).values()];
		if (compareResponse.length) {
			const clonedData = JSON.parse(JSON.stringify(compareResponse));
			this._addDetailUrls(clonedData);
			this._getAllImages(clonedData);
			this._setPanelInnerHTML(clonedData);
			this._setCompareUrl();
			this.checkBox.addEventListener('click', this._setMaxHeight);
			window.addEventListener('scroll', this._collapseComparePanel);
		}
		else {
			this.innerHTML = '';
			if (dom.isElement(this.checkBox)) {
				this.checkBox.removeEventListener('click', this._setMaxHeight);
			}
			window.removeEventListener('scroll', this._collapseComparePanel);
		}
	}

	/**
	 * _handleIntersectionForStickyComparePanel sets and remove sticky state depending from intersection observers
	 * @param {Array<Object>} entries intersection observer entries
	 * @private
	 * @return {void} void
	 */
	_handleIntersectionForStickyComparePanel(entries) {
		setTimeout(() => {
			if (!IntersectionObserverClass.isElementVisible(entries)) {
				if (entries[0] && entries[0].boundingClientRect.top <= this.topSpacingForStickyNav) {
					this.stickyScrollMarker.dataset.topspacing = entries[0].boundingClientRect.top;
					this._addStickyState();
				}
			}
			else {
				if (entries[0] && entries[0].boundingClientRect.top >= this.topSpacingForStickyNav) {
					this.stickyScrollMarker.dataset.topspacing = entries[0].boundingClientRect.top;
					this._removeStickyState();
				}
			}
		}, 0);
	}

	/**
	 * _addStickyState adds the sticky state to the compare panel, sets placeholder space
	 * @private
	 * @returns {void} void
	 */
	_addStickyState() {
		let comparePanelOffsetHeight = dom.getElement(ComparePanelElement.defaults.classStickyWrapper, this).offsetHeight || 0;
		const stickyClassName = ComparePanelElement.defaults.classFixed;
		let comparePanelPlaceholder = dom.getElement(ComparePanelElement.defaults.classComparePanelPlaceholder, this);
		this.comparePanelStickyWrapper.classList.add(stickyClassName);
		this.comparePanelStickyWrapper.style.top = this.topSpacingForStickyNav + 'px';
		comparePanelPlaceholder.style.height = comparePanelOffsetHeight + 'px';
		this.sticky = true;
	}

	/**
	 * _removeStickyState removes the sticky state and resets the space placeholder value
	 * @private
	 * @returns {void} void
	 */
	_removeStickyState() {
		const comparePanelStickyWrapper = dom.getElement(ComparePanelElement.defaults.classStickyWrapper, this);
		const stickyClassName = ComparePanelElement.defaults.classFixed;
		let comparePanelPlaceholder = dom.getElement(ComparePanelElement.defaults.classComparePanelPlaceholder, this);
		comparePanelStickyWrapper.classList.remove(stickyClassName);
		comparePanelStickyWrapper.style.top = '0px';
		comparePanelPlaceholder.style.height = '0px';
		this.sticky = false;
	}

	/**
	 * _collapseComparePanel collapses the panel when scrolling more than 5px
	 * @private
	 * @returns {void} void
	 */
	_collapseComparePanel() {
		this._scrollAmount++;
		if (this._scrollAmount % 5 === 0) {
			if (this.checkBox) {
				this.checkBox.checked = false;
			}
			this._setMaxHeight();
			this._scrollAmount = 0;
			window.removeEventListener('scroll', this._collapseComparePanel);
		}
	}

	/**
	 * _addDetailUrls
	 * @param {Array<Object>} vehicleItems vehicleItems
	 * @private
	 * @returns {void} void
	 */
	_addDetailUrls(vehicleItems) {
		const resultOrFavoriteItemsElement = document.querySelector(ComparePanelElement.defaults.classResultItems) || document.querySelector(ComparePanelElement.defaults.classFavoriteItems);
		if (resultOrFavoriteItemsElement) {
			const scDetailLinkPattern = resultOrFavoriteItemsElement.getAttribute('data-details-url') || '';
			vehicleItems.forEach(vehicle => {
				ComparePanelElement._addDetailLinks(vehicle, scDetailLinkPattern);
			});
		}
	}

	/**
	 * _addDetailLinks adds the specific details link to each vehicle
	 * @method _addDetailLinks
	 * @param {object} vehicle vehicle object
	 * @param {string} linkPattern linkPattern
	 * @private
	 * @returns {void} void
	 */
	static _addDetailLinks(vehicle, linkPattern = '') {
		if (!vehicle.url) {
			vehicle.url = linkPattern.replace(/SC_VEHICLE_ID/i, vehicle.id);
			if (vehicle.url.indexOf('sc_detail') > -1 && vehicle.url.indexOf('sc_detail_fav') < 0) {
				vehicle.url = vehicle.url.replace(/sc_detail/, 'sc_detail_fav');
			}
		}
	}

	/**
	 * _getAllImages
	 * @param {Array<Object>} vehicleItems vehicleItems
	 * @private
	 * @returns {void} void
	 */
	_getAllImages(vehicleItems) {
		vehicleItems.forEach(vehicle => {
			ComparePanelElement._getImage(vehicle);
		});
	}

	/**
	 *  _getImage adds the specific image to each vehicle
	 * @method _getImage
	 * @param {object} vehicle_ vehicle object
	 * @private
	 * @returns {void} void
	 */
	static _getImage(vehicle_ = {}) {
		let image = '', renderImage, fallbackImage;

		const pictureUrls = OBJECT_UTILS.getNestedObject(vehicle_, 'used.pictureUrls'.split('.')) || [];
		if (vehicle_.type === 'U' && pictureUrls.length) {
			image = pictureUrls[0];
		}
		else if (Array.isArray(vehicle_.pictures) && (renderImage = vehicle_.pictures.find(pic => pic.key === 'vtp400n1bg2'))) {
			image = renderImage.url;
		}
		else if (Array.isArray(vehicle_.fallbackPictures) && (fallbackImage = vehicle_.fallbackPictures.find(pic => pic.key === 'render_4x3'))) {
			image = fallbackImage.url;
		}
		vehicle_.image = image;
	}

	/**
	 * _setTopSpacing reads the height of the navigation bar and registers intersection observers according to it
	 * @private
	 * @returns {void} void
	 */
	_setTopSpacing() {
		this.topSpacingForStickyNav = dom.getElement(ComparePanelElement.defaults.classMainNavigation).offsetHeight;

		if (this.sticky) {
			this.comparePanelStickyWrapper.style.top = this.topSpacingForStickyNav + 'px';
		}
		if (dom.isElement(this.stickyScrollMarker)) {
			intersectionObserver.unregisterObserver(this.stickyScrollMarker);
			intersectionObserver.registerObserver(this.stickyScrollMarker, this._handleIntersectionForStickyComparePanel.bind(this), {
				rootMargin: `-${this.topSpacingForStickyNav}px`,
				threshold: [0.5]
			});
		}
	}

	/**
	 * _setPanelInnerHTML renders the panel's inner html
	 * @param {Array<Object>} compareResponse compareResponse
	 * @private
	 * @returns {void} void
	 */
	_setPanelInnerHTML(compareResponse) {
		const itemsString = template.render(comparePanelTemplate, {
			'data': compareResponse,
			'checkedState': true
		});

		if (this.innerHTML === '') {
			dom.appendHtmlString(this, itemsString);
			this.stickyScrollMarker = this.parentElement.querySelector(ComparePanelElement.defaults.scrollChecker);
			this.comparePanelStickyWrapper = dom.getElement(ComparePanelElement.defaults.classStickyWrapper, this);
			this._setTopSpacing();
		}
		else {
			this._replaceNecessaryItems(itemsString);
		}
		this.expandedContent = dom.getElement(ComparePanelElement.defaults.classExpandedContent, this);
		this.checkBox = dom.getElement(ComparePanelElement.defaults.idCompareCheckbox, this);
		this.checkBox.checked = true;
		this._getHeightForAnimation();
	}

	/**
	 * _replaceNecessaryItems - replace only items which should be rerenderd
	 * @param {string} itemsString html string of comparePanel
	 * @private
	 * @return {void} returns void
	 */
	_replaceNecessaryItems(itemsString) {
		const contextFragment = document.createRange().createContextualFragment(itemsString);

		const fragItemsContainer = contextFragment.querySelector(ComparePanelElement.defaults.selectorComparePanelItems);
		const domItemsContainer = this.querySelector(ComparePanelElement.defaults.selectorComparePanelItems);

		const fragExpandedItems = contextFragment.querySelector(ComparePanelElement.defaults.selectorComparePanelExpandedItems);
		const domExpandedItems = this.querySelector(ComparePanelElement.defaults.selectorComparePanelExpandedItems);

		const fragExpandedButton = contextFragment.querySelector(ComparePanelElement.defaults.selectorComparePanelExpandedButton);
		const domExpandedButton = this.querySelector(ComparePanelElement.defaults.selectorComparePanelExpandedButton);

		const fragExpandedButtonContainer = contextFragment.querySelector(ComparePanelElement.defaults.selectorComparePanelCollapsedButton);
		const domExpandedButtonContainer = this.querySelector(ComparePanelElement.defaults.selectorComparePanelCollapsedButton);

		const replacements = [
			[fragItemsContainer, domItemsContainer],
			[fragExpandedItems, domExpandedItems],
			[fragExpandedButton, domExpandedButton],
			[fragExpandedButtonContainer, domExpandedButtonContainer]
		];

		replacements.forEach(replacement => {
			replacement[1] && replacement[1].parentNode.replaceChild(replacement[0], replacement[1]); // eslint-disable-line no-unused-expressions
		});
	}

	/**
	 * _setCompareUrl
	 * @method setCompareUrl
	 * @returns {void} returns nothing
	 */
	_setCompareUrl() {
		const cmpBtns = [];
		const compareIds = SELECTORS.VEHICLES.getCompareVehicleIdsState(STCK_STORE.state);
		const {compareUrl = ''} = this.dataset;
		const sepElement = scPage.getSeparatingElement(compareUrl);
		cmpBtns[0] = dom.getElement(ComparePanelElement.defaults.classCollapsedCompareButtonLink, this);
		cmpBtns[1] = dom.getElement(ComparePanelElement.defaults.classExpandedCompareButtonLink, this);
		cmpBtns.forEach(cmpBtn => {
			if (dom.isElement(cmpBtn) && compareIds) {
				cmpBtn.href = compareUrl + sepElement + 'compare=' + compareIds;
			}
		});
	}

	/**
	 * _onShowHideChangeHandler Check status of checkbox to render template with opened/closed details
	 * @method onShowHideChangeHandler
	 * @returns {void} returns nothing
	 */
	_onShowHideChangeHandler() {
		if (dom.isElement(this.checkBox)) {
			this._checkedState = this.checkBox.checked;
			if (this._checkedState) {
				window.addEventListener('scroll', this._collapseComparePanel);
			}
		}
	}

	/**
	 * _getHeightForAnimation getting expanded content height for animating transition
	 * @private
	 * @returns {void} void
	 */
	_getHeightForAnimation() {
		if (dom.isElement(this.expandedContent) && this.expandedContent.style) {
			this.expandedContent.style.maxHeight = 'none';
			this.expandedContentMaxHeight = this.expandedContent.offsetHeight;
			this._setMaxHeight();
		}
	}

	/**
	 * _setMaxHeight – after getting the height, setting max-height for animation
	 * @private
	 * @returns {void} void
	 */
	_setMaxHeight() {
		if (this.checkBox && this.checkBox.checked) {
			this._checkedState = true;
			this.expandedContent.style.maxHeight = this.expandedContentMaxHeight + 'px';
		}
		else {
			this._checkedState = false;
			this.expandedContent.style.maxHeight = '0';
		}

	}

	/**
	 * add change and resize Event Listener
	 * @method addEventListener
	 * @returns {void} returns nothing
	 */
	_addEventListener() {
		this.update = this.update.bind(this);
		this.unsubscribeFromCompareStore = STCK_STORE.observeStateFromStore(SELECTORS.VEHICLES.getCompareVehicleIdsState, this.update);
		this.domDelegate = dom.getEventDelegate('body');
		this.domDelegate.on('change', ComparePanelElement.defaults.idCompareCheckbox, this._onShowHideChangeHandler);
		window.addEventListener('resize', dom.debounce(this._setTopSpacing, 250));
		window.addEventListener('resize', dom.debounce(this._getHeightForAnimation, 250));
	}

	/**
	 * _removeEventListener removes the change and scroll event listeners
	 * @private
	 * @returns {void} void
	 */
	_removeEventListener() {
		this.unsubscribeFromCompareStore();
		this.domDelegate.off('change', ComparePanelElement.defaults.idCompareCheckbox, this._onShowHideChangeHandler);
		window.removeEventListener('scroll', this._collapseComparePanel);
	}

	/**
	 * update – update trigger
	 * @returns {void} void
	 */
	update() {
		this._renderComparePanelView();
	}
}
if (window.customElements.get('sc-compare-panel-element') === undefined) {
	window.customElements.define('sc-compare-panel-element', ComparePanelElement);
}

